import React from 'react';
import { Link } from 'react-router-dom';

function Portfolio() {
    
    return (
        <>
            <section className="body-section">
                <div className="swatch-1">
                    <h2>Films</h2>
                        <h3><Link to="/coming-soon" className="a-alt4">American Dreamers (Feature) – Editor & Producer</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Jessica (Short) – Editor, Colourist & Producer</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Dobson Centre Videos (Brand Film) – Director & Editor</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Spark (Short) – Colourist & Executive Producer</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Senna (Documentary) - Editor & Producer</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Chef's Table Student Parody – Editor</Link></h3>
                    <br/>
                    <h2>Design</h2>
                        <h3><Link to="/coming-soon" className="a-alt4">UN International Seabed Authority – Creative Director & Motion Graphic Artist</Link></h3>
                    <br/>
                    <h2>Marketing Campaigns</h2>
                        <h3><Link to="/coming-soon" className="a-alt4">Audition Showdown – Creative Director</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Infolinia Canada Website - UX/UI Consultant</Link></h3>
                        <h3><Link to="/coming-soon" className="a-alt4">Khaos Ecosystem Brand Builds - Creative Director</Link></h3>
                    <br/>
                    <h2><Link to="/coming-soon" className="a-alt4">Art</Link></h2>
                    <h3><Link to="/coming-soon" className="a-alt4">Self Portrait in Coffee Beans</Link></h3>
                    <h3><Link to="/coming-soon" className="a-alt4">Landscape Series in Watercolours</Link></h3>
                </div>
            </section>
        </>
    )
}

export default Portfolio;