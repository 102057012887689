import React from 'react';
// import { Link } from 'react-router-dom';
// import IGlogo from '../resources/images/socials/instagram.svg';

function Footer() {
  return (
    <>
        <footer>
            <div class="foot-container">
                <h5 className="copyright">Made by a few too many sleepless nights</h5>
                <h6 class="copyright">Copyright &copy; 2022 George Popi. </h6>
            </div>
        </footer>
    </>
  );
}

export default Footer;
