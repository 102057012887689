import React from 'react';
import ReactDOM from 'react-dom';
import './resources/css/styles.css';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import Portfolio from './Pages/Portfolio';
import About from './Pages/About';
import Blog from './Pages/Blog';

ReactDOM.render(
  <React.StrictMode>
    <Router >
    <Header />
      <Route path='/' component={Home} exact/>
      <Route path='/Portfolio' component={Portfolio} exact/>
      <Route path='/About' component={About} exact/>
      <Route path='/Blog' component={Blog} exact/>
    </Router>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals reportWebVitals();