import React from 'react';
import { Link } from 'react-router-dom';
import CoffeeHistory from '../resources/images/projects/CoffeeHistory.jpg';
import Grain from '../resources/images/projects/Grain.jpg';
import Time from '../resources/images/projects/Time.jpg';
import AmericanDreamer from '../resources/images/projects/AmericanDreamers_productions.jpg';
import Dobson from '../resources/images/projects/Dobson.jpg';
import KhaosBrand from '../resources/images/projects/KhaosBrand.jpg';

function Blog() {
    
    return (
        <>
            <section className="body-section">
                <h2>Latest Musings</h2>
                <h5>Half-baked thoughts to well thought-out ponderings, and everything in-between</h5>
                <section className="blog-block">
                    <img src={Time} alt="Analog clock face"/>
                    <h4>Thoughts on entrepreneurship</h4>
                </section>
                <section className="blog-block">
                    <img src={Grain} alt="visual static"/>
                    <h4>The Issue with Noise</h4>
                </section>
                <section className="blog-block">
                    <img src={CoffeeHistory} alt="map of the world"/>
                    <h4>The History of Coffee</h4>
                </section>
                <br/>  <br/>
                <section className="project-block">
                        <img src={Dobson} alt="still from the video"/>
                        <h4>McGill Dobson Centre - Cohort Videos</h4>
                    </section>
                    <section className="project-block">
                        <img src={KhaosBrand} alt="logo for Khaos, Khaos Labs and Ramen Creative"/>
                        <h4>Khaos Ecosystem, Building Up Brands</h4>
                    </section>
                    <section className="project-block">
                        <img src={AmericanDreamer} alt="still from American Dreamers"/>
                        <h4>American Dreamers - Feature Film</h4>
                    </section>
            </section>
        </>
    )
}

export default Blog;