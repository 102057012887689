import React from 'react';
import { Link } from 'react-router-dom';


function Header() {
  return (
    <>
      <header className="main-nav">
          <div className="main-nav-bar">
              <div className="header-img">
                  <Link to="/">
                     <p>George Popi</p>
                  </Link>
              </div>
              <p>
                  <ul className="header-nav">
                      <li><Link to="/portfolio" class="nav-item first">Portfolio</Link></li>
                      <li><Link to="/blog" class="nav-item first">Blog</Link></li>
                      <li><Link to="/about" class="nav-item first">About</Link></li>
                  </ul>
              </p>

          </div>
      </header>
    </>
  );
}

export default Header;
