import React from 'react';
import { Link } from 'react-router-dom';
import GeorgePicAbout from '../resources/images/GeorgePic_About.JPG';

function About() {
    
    return (
        <>
            <section className="body-section">
                <section className="about-block">
                    <img src={GeorgePicAbout} alt="Portrait of George"/>
                    <br/><br/>
                    <h3>Highlight Works</h3>
                    <h5>Senna (Documentary) - Editor & Producer</h5>
                    <h5>Chef's Table Student Parody – Editor</h5>
                </section>
                <section className="about-block">
                    <p>
                    Hello, welcome!
                        I am an interdisciplinary artist, entrepreneur and futurist.
                        <br/><br/>
                        Since 2016, I have been primarily working on projects involving the human condition, and examining the threads that hold our past and present together to be able to bend the future into possibilities our ancestors could not, or did not want us, to imagine.
                        <br/><br/>
                        In pursuit of that lofty and (seeimingly) vague vision; I founded <a href="https://createkhaos.com" target="_blank">Khaos</a>, a company whose mission is to build and support culture first teams solving niched industry problems.
                        <br/><br/>
                        Khaos has spun off a few subsidiaries over the years; including the indie film production company, <a href="https://khaoslabs.com" target="_blank">Khaos Labs</a>, and marketing agencies <a href="https://showmetheramen.com" target="_blank">Ramen Creative</a> and <a href="https://whskylane.com" target="_blank">Whisky Lane</a>. All of which I am actively involved with on a day-to-day basis as I love bridging the gap between the artistic, technical and business spheres.
                        <br/><br/>
                        While I have the regular pleasure of working and managing many talented creatives, I still get my own hands “dirty.” I’ve had the pleasure of being the editor on the vast majority of projects that Khaos Labs has produced including our first feature film, <a href="https://www.youtube.com/watch?v=iWJ-N0wAPfY" target="_blank">American Dreamers</a>, as well as regularly coding and designing digital works for clients.
                        <br/><br/>
                        I do have the travel bug, but I’ve been calling the Okanagan Valley in British Columbia my home since 2020 after spending 6 years in Montreal and growing up in London, Ontario.
                        <br/><br/>
                        I always love chatting about new projects and seeing how best I can support others, shoot me an email at <a href="mailto:contact@georgepopi.com" className="a-alt2">contact@georgepopi.com</a> if there’s anything you want to discuss.
                        <br/><br/>
                        <p>––––</p><br/>
                        PS: During the pandemic, my daily cafe pilgrimage for espressos was upended. That resulted in the spiral of finally getting a coffee machine at home which quickly turned into roasting coffee and ravenously learning the history and stories around how that bean. I’ve started doing research for a new historical docu-series examining flashpoints where coffee seemed to be intertwined with larger historical movements were happening at the same time (is it a coincidence the Enlightenment happened right as coffee was introduced to Europe? Why did England stop drinking coffee while it became the American drink?). You can keep up to date on that project, as well as ordering coffee, through the newest subsidiary of Khaos, <a href="https://cracklingcoffee.com" target="_blank">Crackling Coffee</a>.
                    </p>
                </section>

            </section>
        </>
    )
}

export default About;