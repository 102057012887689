import React from 'react';
import GeorgePic from '../resources/images/GeorgePic.JPG';
import { Link } from 'react-router-dom';

function Home() {
    
    return (
        <>
            <main className="swatch-1">
                <section className="body-section">
                    <section className="text=block">
                        <h1>Hi, I'm<br/> George Popi.</h1>
                        <h2>
                            <div className="wrapper">I get called a
                                <br/>
                                <div className="words">
                                    <span><Link to="/portfolio" className="a-alt2">Creative</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">Film Editor</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">UX/UI Designer</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">Creative Director</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">Producer</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">Futurist</Link></span>
                                    <span><Link to="/portfolio" className="a-alt2">Creative</Link></span>
                                </div>
                            </div>
                        </h2>
                    </section>
                    <section className="desktop-img">
                        <img src={GeorgePic} alt="George chilling on top of an RV"/>
                    </section>
                    <section className="home-block">
                    <br/><br/><br/>
                        <h3>
                            This is my digital playground and portfolio site.
                        <br/><br/>
                            Here you'll get a sense of the projects I enjoy being involved in.
                        </h3>
                        <br/>
                    </section>
                    <br/> <br/> <br/>
                    <p>
                        Ultimately, I love shaping stories and refining ideas that can resonate with others.
                        <br/><br/>
                        <a href="mailto:contact@georgepopi.com" className="a-alt2">Reach out</a> if there's any idea that you want me to bounce back at you!
                    </p>
                </section>
            </main>
        </>
    )
}

export default Home;